<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial ">
            <el-form ref="formData" :model="formData" label-width="80px">
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,短信数据不是实时)':''}}当前：操作日志</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="6" :sm="12" :md="8">
                                <el-date-picker
                                        style=" margin-top: 8px; width: 320px;margin-bottom: 8px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        :clearable="false"
                                        size="mini"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        format="yyyy 年 MM 月 dd 日"
                                        value-format="yyyy-MM-dd"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <el-col :lg="7">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px " id="createBill" type="primary" round size="mini"
                                           @click="searchList">搜索查询
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                highlight-hover-row
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :row-class-name="tableRowClassName"
                                :mouse-config="{selected: false}"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:false}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,2500,5000,50000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
    </div>
</template>
<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";

    export default {


        data() {
            let end = new Date();
            let start = new Date();
            //如果是一月份，年则向上查
            var vue = this;//重要，vue在data数据调用this
            return {
                pickerOptions: {
                    onPick(time) {
                        // 这里可以写执行之后的逻辑 用户选择一次时间范围会触发两次
                        //当第一时间选中才设置禁用
                        if (time.minDate && !time.maxDate) {
                            //console.log('第一次执行设置值');
                            vue.timeOptionRange = time.minDate;
                       }
                        if (time.maxDate) {
                            vue.timeOptionRange = null;
                       }
                        //console.log("onPick: " + time);
                        //console.log(time);
                   },
                    disabledDate: (time) => {
                        let timeOptionRange = this.timeOptionRange;
                        if (timeOptionRange) {
                            return time.getTime() < new Date(timeOptionRange).setMonth(0) || time.getTime() > new Date(timeOptionRange).setMonth(11) || time.getTime() > new Date().setMonth(new Date().getMonth())
                       } else {
                            return time.getTime() > new Date().setMonth(new Date().getMonth())
                       }
                        //return time.getTime() > new Date().setMonth(new Date().getMonth() - 1)//大于等于今年今月不能筛选
                        // return time.getTime() < (new Date((new Date().getFullYear()).toString())).getTime() || time.getTime() > new Date().setMonth(new Date().getMonth() - 1)
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   },{
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   },]
               },
                tableDataMain: [],
                tableColumn: [
                    {field:'id', width: 100 ,title: '编号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/logger/searchHeard', this.pickerDate,null,null,this.searchList)
                       }
                   },
                    {field:'created', width: 180, title: '日期', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/logger/searchHeard', this.pickerDate,null,null,this.searchList)
                       }
                   },
                    {
                        field:'nick_name',width: 100,  title: '用户名称', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/logger/searchHeard', this.pickerDate,null,null,this.searchList)
                       }
                   },
                    {
                        field:'user_name',width: 100,  title: '账户', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/logger/searchHeard', this.pickerDate,null,null,this.searchList)
                       }
                   },
                    {
                        field:'ip', title: 'IP地址',width: 100,  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/logger/searchHeard', this.pickerDate,null,null,this.searchList)
                       }
                   },
                    {
                        field:'context', title: '操作内容',width: 380,
                        editRender: { name: 'ElInput',props: {readonly:true,controls:false}},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/logger/searchHeard', this.pickerDate,null,null,this.searchList)
                       }
                   },
                    {
                        field:'action', title: '访问地址',width: 340,  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/logger/searchHeard', this.pickerDate,null,null,this.searchList)
                       }
                   },
                    {
                        field:'remark', title: '操作',width: 180,  slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/logger/searchHeard', this.pickerDate,null,null,this.searchList)
                       }
                   },

                ],
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 1000
               },
                filterData: {},
                sortData: {},
                pickerDate: [start, end],//查询年限 月份
                formData: {
                    companyUserName: '',
                    money: '',
                    payAccount: '',
                    bankName: '',
               },
           }
       },
        methods: {

            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getList();
           },
            searchList() {
                this.tablePage.currentPage = 1
                this.getList();
           },
            getList() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method: 'POST',
                    url: '/admin/logger/getList',
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        pickerDate: this.pickerDate,
                        sortData: this.sortData,
                        filterData: this.filterData,
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.list
                        this.tablePage.pageSize = response.data.pageSize
                        this.tablePage.total = response.data.totalRow
                        this.tablePage.currentPage = response.data.pageNumber
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           }
       }
        ,
        created() {
            this.loading = true
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                //this.$refs.xGrid.connect("123")
                this.getList();
           })

       }
   }
    ;


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }
</style>
